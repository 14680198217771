import React, { useEffect, useState } from 'react';
import useLogin from '../../hooks/useLogin';
import usePlayer from '../../hooks/usePlayer';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../Constants';
import { CSSTransition } from 'react-transition-group';

export default function Login() {
	const { playerId } = usePlayer();
	const { OnClickLoginWithUser } = useLogin();
	const [customId, setCustomId] = useState<string|null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (playerId) {
			navigate(ROUTES.ROOT, { replace: true });
		}
	}, [playerId, navigate]);

	function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === 'Enter') {
			OnClickLoginWithUser(customId);
		}
	}

	return (
		<div className="page login">
			<CSSTransition
				appear
				in
				timeout={1200}
			>
				<div className="login-form">
					<h1>XR Server<br />Demo Library</h1>
					<p>Enter your email to get access to your specific demo&nbsp;environments.</p>
					<input type="text" placeholder="Identifier" onChange={(e) => setCustomId(e.currentTarget.value)} onKeyDown={handleKeyDown} />
					<button onClick={() => OnClickLoginWithUser(customId)} className="button">Login</button>
				</div>
			</CSSTransition>
		</div>
	);
}