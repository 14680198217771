export const GLOBAL_NAMESPACE = 'rem-strike';

export const APP_VERSION = '2023-04-28.1';

export const XR_CONFIG = {
	apiUrl: process.env.REACT_APP_XR_API_URL,
	appId: process.env.REACT_APP_XR_APP_ID,
} as IXRConfig;

export const PLAYFAB_CONFIG = {
	appId: process.env.REACT_APP_PLAYFAB_TITLE_ID,
} as IPlayFabConfig;


export const REALTIME_CONFIG = {
	apiKey: process.env.REACT_APP_REALTIME_API_KEY,
	apiUrl: process.env.REACT_APP_REALTIME_API_URL,
} as IRealtimeConfig;

export const API_CONFIG = {
	xr: XR_CONFIG,
	playfab: PLAYFAB_CONFIG,
	realtime: REALTIME_CONFIG,
} as IApiConfig;

export const DEFAULT_LANG = 'en';

export const CDN_BASE = '';

export const DEFAULT_ERROR_MESSAGE = 'An error has occurred';

export const BASE_PATH = process.env.REACT_APP_BASE_PATH;

export const ROUTES = {
	ROOT: '/',
	LOGIN: 'login',
};

export const EXCLUDED_LOGGER_ACTIONS = [];

export const POLL_RATES = {
	SEND_HEARTBEAT: 1000 * 60,
};

export const ITEM_CLASSES = {
	OVERRIDE: 'Override',
	BADGES: 'Badges',
	TRIGGER_BUTTONS: 'Trigger Buttons',
};

export const MISSIONS_TYPES = {};

export enum QuestionTypes {
	Multiple = 'multiple',
	MultipleImage = 'multiple-image',
	Inputs = 'inputs',
}

export const PREDICTION_MATCH_ID = 'match-id';