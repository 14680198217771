import { useEffect, useRef } from "react";

export default function useScrollArrows(scrollerRef: React.MutableRefObject<HTMLElement>) {
	const scrollAnimationFrame = useRef<number>();

	const endScroll = () => {
		cancelAnimationFrame(scrollAnimationFrame.current);
	};

	const scrollBtnEvents = (scrollDelta: number) => {
		const scroller = scrollerRef.current;

		return {
			onPointerDown: () => {
				const scroll = () => {
					scroller?.scrollBy({ left: scrollDelta });
					scrollAnimationFrame.current = requestAnimationFrame(scroll);
				};
				scrollAnimationFrame.current = requestAnimationFrame(scroll);
			},
			onPointerUp: endScroll,
			onPointerLeave: endScroll,
		};
	};

	useEffect(() => {
		return endScroll;
	}, []);

	return {
		scrollBtnEvents,
		endScroll,
	};
}
