import { createSlice } from '@reduxjs/toolkit';
import xrAction from './async/xrAction';
import { getXrApi } from '../api/apiBridge';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';

export const getFormulas = createDebouncedAsyncAction(
	'playfab-xr/getFormulas',
	xrAction(() => {
		return getXrApi().Client.GetFormula({});
	}),
);

export const executeFormula = createDebouncedAsyncAction(
	'playfab-xr/executeFormula',
	xrAction((recipeId:number) => {
		return getXrApi().Client.ExecuteFormula({
			FormulaId: recipeId,
		});
	}),
);

const crafting = createSlice({
	name: 'crafting',
	initialState: {
		formulas: [] as IXRFormulaDefinition[],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getFormulas.actions.fulfilled, (state, action) => {
			return {
				...state,
				formulas: action.payload.data.FormulaDefinitions,
			};
		});
	},
});

export default crafting;