import { createSlice } from '@reduxjs/toolkit';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import { getPlayerCombinedInfo } from './playfab';
import xrAction from './async/xrAction';
import { getXrApi } from '../api/apiBridge';

export type IPlayerStatistics = {
	xp: number,
	level: number,
};

export const getPlayerStatistics = createDebouncedAsyncAction(
	'playfab-xr/GetPlayerStatistics',
	xrAction(getXrApi().Client.GetPlayerStatistics),
);

const defaultPlayerStatistics:IPlayerStatistics = {
	xp: 0,
	level: 0,
};

const statistics = createSlice({
	name: 'statistics',
	reducers: {
		updateLocalStatistic: (state, action:{ payload: { name: keyof IPlayerStatistics, value: number } }) => {
			const { name, value } = action.payload;
			state[name] = value;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getPlayerStatistics.actions.fulfilled, (state, action) => {
			const newStats = action.payload.data?.Statistics?.reduce((c, stat) => {
				c[stat.StatisticName] = stat.Value;
				return c;
			}, {} as Record<string, number>);

			return {
				...state,
				...newStats,
			};
		});
		builder.addCase(getPlayerCombinedInfo.actions.fulfilled, (state, action) => {
			const newStats = action.payload.data?.InfoResultPayload?.PlayerStatistics?.reduce((c:IPlayerStatistics, stat:{ StatisticName:keyof IPlayerStatistics, Value:number }) => {
				c[stat.StatisticName] = stat.Value;
				return c;
			}, {});

			return {
				...state,
				...newStats,
			};
		});
	},
	initialState: defaultPlayerStatistics,
});

export default statistics;

export const { updateLocalStatistic } = statistics.actions;