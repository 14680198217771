import { createSlice } from '@reduxjs/toolkit';
import { getXrApi } from '../api/apiBridge';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import xrAction from './async/xrAction';

export const getGlobalVariables = createDebouncedAsyncAction(
	'playfab-xr/getGlobalVariables',
	xrAction(() => {
		return getXrApi().Client.GetGlobalVariable();
	}),
);

type DemoTile = {
	name: string;
	url: string;
	thumb: string;
}

type DemoMapping = {
	[key: string]: {
		name: string;
		demos: DemoTile[];
	}
};

const initialState = {
	isLoaded: false,
	demo_mapping: null as DemoMapping,
};

const globalVariables = createSlice({
	name: 'globalVariables',
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getGlobalVariables.actions.fulfilled, (state, action) => {
			return {
				...state,
				isLoaded: true,
				...action.payload.data.GlobalVariables.reduce((acc, curr) => {
					acc[curr.dataKey] = curr.dataVal;
					return acc;
				}, {} as Record<string, any>),
			};
		});
	},
	initialState,
});

export default globalVariables;
