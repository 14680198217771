import React, { memo } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { ROUTES } from './Constants';
import '../css/index.scss';
import Home from './components/routes/Home';
import usePlayFab from './hooks/usePlayFab';
import useRealtime from './hooks/useRealtime';
import useInventoryExpiration from './hooks/useInventoryExpiration';
import useHeartbeat from './hooks/useHeartbeat';
import Debug from './components/Debug';
import Login from './components/routes/Login';
import { useLogout } from './hooks/useLogout';

function App() {
	/*
	* Initialize PlayFab XR connection (once logged in, we fetch all the player data, you should)
	* only use this hook once, and only at the root of your app.
	*/
	const { playerId } = usePlayFab();
	const { logout } = useLogout();

	// Connect to realtime if the service is active in this app
	useRealtime();

	// Check for expired inventory items
	useInventoryExpiration();

	// Send a heartbeat to XR
	useHeartbeat();

	const isLogin = useMatch(ROUTES.ROOT + ROUTES.LOGIN);

	return (
		<>
			{/* If we're not logged in, and we're not on the login page, redirect to the login page */}
			{!isLogin && !playerId && <Navigate to={ROUTES.LOGIN} replace={true} />}
			<Debug />
			<Routes>
				<Route path={ROUTES.ROOT}>
					<Route index element={<Home />} />
					<Route path={ROUTES.LOGIN} element={<Login />} />
				</Route>
			</Routes>
			<div className="background">
				<img src="./assets/bg.jpg" alt="" />
			</div>
			<header>
				<img src="./assets/logo.png" alt="XR Server" />

				{playerId && <span onClick={logout}>Logout</span>}
			</header>
		</>
	);
}

export default memo(App);
