import React, { CSSProperties, memo, useRef } from 'react';
import usePlayerDemos from '../../hooks/usePlayerDemos';
import usePlayer from '../../hooks/usePlayer';
import { ArrowLeftIcon, ArrowRightIcon } from '../ui/Icons';
import useScrollArrows from '../../hooks/useScrollArrows';
import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import useGlobalVariables from '../../hooks/useGlobalVariables';

function Home() {
	const { playerId, DisplayName } = usePlayer();
	const { isLoaded } = useGlobalVariables();
	const demoList = usePlayerDemos();
	const rootRef = useRef<HTMLDivElement>(null);

	const scrollerRef = useRef<HTMLDivElement>(null);

	const { scrollBtnEvents } = useScrollArrows(scrollerRef);

	const { context } = useGSAP(ctx => {
		if (!playerId || !DisplayName || !isLoaded) return;

		const q = gsap.utils.selector(rootRef);

		const [bgVideo] = q('.bg-video') as HTMLVideoElement[];

		const videoLoaded = bgVideo.readyState >= 3; // 3 => HAVE_FUTURE_DATA

		const inTl = gsap
			.timeline({
				paused: !videoLoaded,
			})
			.from(bgVideo, {
				opacity: 0,
				duration: 0.3,
			})
			.add(() => {
				bgVideo.play();
			})
			.from('h1', {
				duration: 0.5,
				yPercent: 5,
				opacity: 0,
			}, '+=0.5')
		;

		if (demoList?.length > 0) {
			inTl.from('.demo-link', {
				duration: 0.3,
				yPercent: 5,
				opacity: 0,
				stagger: 0.1,
			}, '<50%')
		} else {
			inTl.from('.no-demos', {
				duration: 0.3,
				yPercent: 5,
				opacity: 0,
			}, '<50%')
		}

		ctx.add('startAnim', () => {
			inTl.play(0);
		});
	}, {
		scope: rootRef,
		dependencies: [
			isLoaded,
			playerId,
			DisplayName,
			demoList,
		],
		revertOnUpdate: true,
	});

	if (!playerId || !DisplayName || !isLoaded) {
		return null;
	}

	const showArrows = demoList && demoList.length > 4;

	return (
		<div className="page home" ref={rootRef}>
			<video
				className="bg-video"
				onCanPlayThrough={() => {
					context?.startAnim?.();
				}}
			>
				<source src="https://xrsalesdemo.blob.core.windows.net/xr-sales-images/8lrdfs/assets/3D-beautyshot-logo.mp4" type="video/mp4" />
			</video>
			<h1 className={!showArrows ? 'no-arrows' : ''}>
				<span>Hi {DisplayName}</span>
				DEMO LIBRARY
			</h1>

			{(!demoList || demoList.length === 0) && <p className="no-demos">You don't have any demos yet. Please contact the innovation department to get access.</p>}

			{showArrows && (
				<div className="arrows">
					<button className="arrow" {...scrollBtnEvents(-8)} data-scroll-btn>
						<ArrowLeftIcon />
					</button>
					<button className="arrow" {...scrollBtnEvents(8)} data-scroll-btn>
						<ArrowRightIcon />
					</button>
				</div>
			)}
			{demoList && demoList.length > 0 && (
				<div className="demo-list-wrapper" ref={scrollerRef} data-scroller>
					<div className="demo-list">
						{
							demoList.map((demo, i) => (
								<a href={demo.url + `&PlayFabId=${playerId}`} target="_blank" key={demo.name} rel="noreferrer" className="demo-link" style={{ "--i": i } as CSSProperties}>
									<img src={demo.thumb} alt="" />
									<span>{demo.name}</span>
								</a>
							))
						}
					</div>
				</div>
			)}
		</div>
	);
}

export default memo(Home);
