/* eslint-disable @typescript-eslint/naming-convention */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { QuizTypes } from '../api/enums';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import { getXrApi } from '../api/apiBridge';

type IQuizState = typeof initialState;

const initialState = {
	list: {
		[QuizTypes.Prediction]: [] as XRQuizInstanceItem[],
		[QuizTypes.Trivia]: [] as XRQuizInstanceItem[],
		[QuizTypes.Survey]: [] as XRQuizInstanceItem[],
	},
	data: {} as Record<string, XRQuizInstanceData>,
};

export const getQuizInstances = createDebouncedAsyncAction(
	'quiz/getQuizInstances',
	(data: { MatchId?: string, Limit?: number; Offset?: number; } = {}) => {
		const api = getXrApi();
		return api.Client.GetQuizInstances(data) || Promise.reject('No API');
	},
);

export const getTriviaInstances = createDebouncedAsyncAction(
	'quiz/getTriviaInstances',
	(data: { MatchId?: string, Limit?: number; Offset?: number; } = {}) => {
		const api = getXrApi();
		return api.Client.GetTriviaInstances(data) || Promise.reject('No API');
	},
);

export const getSurveyInstances = createDebouncedAsyncAction(
	'quiz/getSurveyInstances',
	(data: { MatchId?: string, Limit?: number; Offset?: number; } = {}) => {
		const api = getXrApi();
		return api.Client.GetSurveyInstances(data) || Promise.reject('No API');
	},
);

export const getPredictionInstances = createDebouncedAsyncAction(
	'quiz/getPredictionInstances',
	(data: { MatchId?: string, Limit?: number; Offset?: number; } = {}) => {
		const api = getXrApi();
		return api.Client.GetPredictionInstances(data) || Promise.reject('No API');
	},
);

export const getQuizData = createDebouncedAsyncAction(
	'quiz/getQuizData',
	(data: { url: string }) => {
		return fetch(data.url).then((response) => response.json() as Promise<XRQuizInstanceData>);
	},
);


const quiz = createSlice({
	name: 'quiz',
	reducers: {
		
	},
	extraReducers: (builder) => {

		const addQuizInstances = (state:IQuizState, action:PayloadAction<GenericApiCallResponse<GetQuizInstancesResponse>>) => {
			const instances = action.payload.data.QuizInstances;
			if (!instances) return;

			instances.forEach((instance) => {
				state.list[instance.type].push(instance);
			});
		};

		builder.addCase(getQuizInstances.actions.fulfilled, addQuizInstances);
		builder.addCase(getTriviaInstances.actions.fulfilled, addQuizInstances);
		builder.addCase(getSurveyInstances.actions.fulfilled, addQuizInstances);
		builder.addCase(getPredictionInstances.actions.fulfilled, addQuizInstances);
		builder.addCase(getQuizData.actions.fulfilled, (state, action) => {
			const { id } = action.payload;
			state.data[id] = action.payload;
		});
	},
	initialState,
});

export default quiz;