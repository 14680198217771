/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';
import { getXrApi } from '../api/apiBridge';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import xrAction from './async/xrAction';

type IXrStoreState = typeof initialState;

const initialState = {
	loadout: [] as IXRStore[],
};

export const getStoreLoadout = createDebouncedAsyncAction(
	'playfab-xr/getStoreLoadout',
	xrAction(() => {
		return getXrApi().Client.GetStoreLoadout();
	}),
);

export const refreshStoreTile = createDebouncedAsyncAction(
	'playfab-xr/refreshStoreTile',
	xrAction((data:{ TileId:string }) => {
		return getXrApi().Client.RefreshStoreTile(data);
	}),
);

export const purchaseStoreItem = createDebouncedAsyncAction(
	'playfab-xr/purchaseStoreItem',
	xrAction((data:{ TileId:string, CurrencyCode:string }) => {
		return getXrApi().Client.PurchaseStoreItem(data);
	}),
);

const xr_store = createSlice({
	name: 'xr_store',
	reducers: {
	},
	extraReducers: (builder) => {
		builder.addCase(getStoreLoadout.actions.fulfilled, (state:IXrStoreState, action) => {
			state.loadout = [...action.payload.data.StoreLoadout];
		});

		builder.addCase(purchaseStoreItem.actions.fulfilled, (state:IXrStoreState, action) => {
			const tileId = action.meta.arg.TileId;
			const loadout = state.loadout.find(l => l.tiles.find(tile => tile.id === tileId));

			state.loadout = state.loadout.map(l => {
				const tile = loadout?.tiles.find(t => t.id === tileId);

				if (tile) {
					return {
						...l,
						tiles: l.tiles.map(t => {
							if (t.id === tile.id) {
								return {
									...t,
									purchaseCount: action.payload.data.PurchaseCount,
									status: action.payload.data.TileStatus,
								};
							}
							return t;
						}),
					};
				}

				return l;
			});
		});

		builder.addCase(refreshStoreTile.actions.fulfilled, (state:IXrStoreState, action) => {
			const loadout = state.loadout.find(l => l.tiles.find(tile => tile.id === action.payload.data.StoreTile.id));
			
			state.loadout = state.loadout.map(l => {
				const tile = loadout?.tiles.find(t => t.id === action.payload.data.StoreTile.id);

				if (tile) {
					return {
						...l,
						tiles: l.tiles.map(t => {
							if (t.id === tile.id) {
								return action.payload.data.StoreTile;
							}
							return t;
						}),
					};
				}

				return l;
			});
		});
	},
	initialState,
});

export default xr_store;
